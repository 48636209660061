
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { baseViewMixin } from '@/mixins/baseViewMixin'

@Component({})
export default class UserRightsViewer extends mixins(baseViewMixin) {
  views = [
    { text: 'User', value: 'user' },
    { text: 'Entity', value: 'entity' }
  ]
  entityTypes = [
    { text: 'Holding', value: 'holding' },
    { text: 'Company', value: 'company' },
    { text: 'Seat', value: 'siege' },
    { text: 'Advertiser', value: 'advertiser' }
  ]
  selectedView = ''
  selectedEntityType = ''
  email = ''
  id = ''
  entityRightsHeaders = [
    { text: 'User email', value: 'email' },
    { text: 'Related entity type', value: 'user_of', sort: this.customSort },
    { text: 'Name', value: 'related_entity' },
    { text: 'Role', value: 'role' }
  ]
  entityRights: any[] = []
  userRightsHeaders = [
    { text: 'Entity type', value: 'user_of', sort: this.customSort },
    { text: 'Name', value: 'related_entity' },
    { text: 'Role', value: 'role' }
  ]
  userRights: any[] = []
  customSortIndex: string[] = ['advertiser', 'siege', 'company', 'holding']
  loading: boolean = false
  loadedEntities: boolean = false
  pagination = {
    itemsPerPage: 10,
    page: 1,
    sortBy: ['user_of'],
    sortDesc: [false]
  }
  totalItems: number = 0

  async mounted () {
    this.onMounted()
  }

  async callGetEntityRights () {
    let pages = {
      limit: this.pagination.itemsPerPage,
      offset: this.pagination.itemsPerPage * (this.pagination.page - 1),
      sort_by: this.pagination.sortBy[0],
      desc: this.pagination.sortDesc[0]
    }
    this.loadedEntities = false
    this.loading = true
    const res = await this.$apiCaller.getUserRightsOnEntity(this.selectedEntityType, this.id, pages)
    if (this.$apiCaller.isResponseError(res)) {
      console.warn('Error when getting user rights on entity')
      this.entityRights = []
      this.totalItems = 0
    } else {
      this.userRights = []
      this.entityRights = res.data.items
      this.totalItems = res.data.total_items
    }
    this.loadedEntities = true
    this.loading = false
  }
  async callGetUserRights () {
    let pages = {
      limit: this.pagination.itemsPerPage,
      offset: this.pagination.itemsPerPage * (this.pagination.page - 1),
      sort_by: this.pagination.sortBy[0],
      desc: this.pagination.sortDesc[0]
    }
    this.loadedEntities = false
    this.loading = true
    const res = await this.$apiCaller.getUserRightsOfUser(this.email, pages)
    if (this.$apiCaller.isResponseError(res)) {
      console.warn('Error when getting rights of user')
      this.userRights = []
      this.totalItems = 0
    } else {
      this.entityRights = []
      this.userRights = res.data.items
      this.totalItems = res.data.total_items
    }
    this.loadedEntities = true
    this.loading = false
  }

  @Watch('selectedView')
  resetData () {
    this.entityRights = []
    this.userRights = []
    this.totalItems = 0
  }

  @Watch('pagination')
  paginateAndSort (value: {itemsPerPage: number, page: number, sortBy: [string], sortDesc: [boolean]}) {
    if (this.totalItems !== 0 && this.loadedEntities) {
      if (this.userRights.length > 0) {
        this.callGetUserRights()
      } else if (this.entityRights.length > 0) {
        this.callGetEntityRights()
      }
    }
  }

  customSort (a: string, b: string) {
    return this.customSortIndex.indexOf(a) < this.customSortIndex.indexOf(b) ? -1 : 1
  }
}
